import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  body {
    --darken-bg: #2b2b2b;
    --lighten-bg: #2f2f2f;
    --main-color: #d40000;
    --text-color: #fff;
    --sub-text-color: #cfcfcf;
    --theme-switch: #a0a0a0;
    --theme-switch-hover: #fff;
    --menu-bar-bg: #2f2f2f;
    --menu-bar-border: #444444;
    --menu-normal-text: #fff;
    --menu-normal-bg: #3c3c3c;
    --menu-hover-text: #fff;
    --menu-hover-bg: #d40000;
    --menu-border: #666666;
    --widget-bg: #2d2d2d;
    --widget-title-bg: #d40000;
    --widget-title-text: #fff;
    --widget-body-text: #fff;
    --widget-hover: #4b4b4b;
    --footer-bg: #3c3c3c;
    --footer-border: #d40000;
    --footer-text: #fff;
    --comments-bg: #d0d0d0;
    --image-border: #555555;

    margin: 0;
    background-color: var(--darken-bg);
    font-family: "Helvetica Neue", "Helvetica", "sans-serif";
    font-size: 14px;
    color: var(--text-color);
  }
`

export default GlobalStyles
