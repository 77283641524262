import * as React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import GlobalStyles from "../GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"

const PageContainer = styled.div`
  background-color: var(--lighten-bg);
  width: 100%;
  max-width: 1260px;
  min-height: 100vh;
  margin: 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`

const Header = styled.div`
  text-align: center;
  padding: 40px 20px 20px;
`

const Content = styled.div`
  padding: 20px 40px;
`

const Footer = styled.div`
  background-color: #000;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-weight: bold;
  font-size: 1.1rem;
`

const Layout = ({ children }) => {

  return (
    <>
      <GlobalStyles/>
      <PageContainer>
        <Header>
          <StaticImage
            src="../images/logo-header-dark.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Redark logo" />
        </Header>
        <Content>
          {children}
        </Content>
        <Footer>
          © {new Date().getFullYear()}, REDARK.pl
        </Footer>
      </PageContainer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
